import axios from 'axios';
import qs from 'qs';
import {
  getMobAppVersion,
  isPaybisAndroidApp,
  isPaybisAppleApp,
} from '@paybis/frontend-common-lib/src/services/web-view-service';

const getUserAgentHeader = () => {
  const mobAppVersion = getMobAppVersion();
  let agentHeader = `pb-front/${APP_VERSION}`;

  if (isPaybisAndroidApp()) {
  	agentHeader = `pb-mob-app/${mobAppVersion} (Android) ${agentHeader}`;
  }

  if (isPaybisAppleApp()) {
  	agentHeader = `pb-mob-app/${mobAppVersion} (iOS) ${agentHeader}`;
  }

  return agentHeader;
};

axios.defaults.headers.Accept = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['X-PB-User-Agent'] = getUserAgentHeader();
axios.defaults.paramsSerializer = qs.stringify;

export default axios;
